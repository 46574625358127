<template>
    <div class="mt-6">
        <h1> 401 </h1>
        <h3> Nta burenganzira ufite bwo kwinjira aha</h3>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>